import React, { Component } from 'react';



import {
  Dimensions,
  View,
  Image,
  TouchableOpacity,

} from 'react-native';


import { mFontSize, MainColor, boldFont} from './Controller';
import { Icon } from 'react-native-elements';
import Swiper from 'react-native-web-swiper';

const imagesrc = [{
    image:require('./images/pk01.png')},{
    image:require('./images/pk02.png')},{
    image:require('./images/pk03.png')},{
    image:require('./images/pk04.png')},{
    image:require('./images/pk06.png')},{
    image:require('./images/pk05.png')}
];

export default class ProduktSliderTablet extends Component {

constructor(props) {
    super(props);

    this.state = {
            autoPlay: true,
    };
  }


componentDidMount(){

}

componentWillUnmount() {

}

setPlayer(){

    if ( this.state.autoPlay ){

        this.refs.Swiper.stopAutoplay();
        this.setState({ autoPlay: false });

    } else {

        this.refs.Swiper.startAutoplay();
        this.setState({ autoPlay: true });
    }

}

getScreenSize(){
    let w = Dimensions.get('window').width;
    let h = Dimensions.get('window').height;
    let isTablet = true;
    if ( h > w ){
        isTablet = false
    }

    return { width: w, height: h, isTablet:isTablet };
}

getThumbWidth(){
return this.getScreenSize().width/10*2.5;
}

render() {




return (
<View style={{ width: this.getScreenSize().width, height: this.getThumbWidth()/3*2+50, marginTop:0, marginBottom:0, justifyContent:'center', alignSelf:'center', }}>

<Swiper
    ref={'Swiper'}
    from={0}
    loop
    timeout={2}
    springConfig={{ speed: 11, bounciness: 1 }}
    minDistanceForAction={0.15}
      controlsProps={{
        prevTitle: '<',
        nextTitle: '>',
        dotsTouchable: true,
        dotsPos: false,//'bottom',
        dotActiveStyle: { backgroundColor: MainColor.streuwerkrot},
        prevPos: false,
        nextPos: false,
        cellsStyle: {
          'bottom': { marginTop: 30, },
        },
        prevTitleStyle: { marginRight: 50, fontSize:mFontSize.swiper, fontFamily:boldFont, color:MainColor.grayultralight },
        nextTitleStyle: { marginLeft: 50, fontSize:mFontSize.swiper, fontFamily:boldFont, color:MainColor.grayultralight }
      }}
        >

<View style={{ alignSelf:'center', width: this.getScreenSize().width*0.8, flexDirection:'row', justifyContent:'space-between',  }} >
    <Image style={{width: this.getThumbWidth(), height: this.getThumbWidth()/3*2, }} resizeMode="contain" source={imagesrc[0].image} />
    <Image style={{width: this.getThumbWidth(), height: this.getThumbWidth()/3*2, }} resizeMode="contain" source={imagesrc[1].image} />
    <Image style={{width: this.getThumbWidth(), height: this.getThumbWidth()/3*2, }} resizeMode="contain" source={imagesrc[2].image} />
</View>
<View style={{ alignSelf:'center', width: this.getScreenSize().width*0.8, flexDirection:'row', justifyContent:'space-between',  }} >
    <Image style={{width: this.getThumbWidth(), height: this.getThumbWidth()/3*2, }} resizeMode="contain" source={imagesrc[3].image} />
    <Image style={{width: this.getThumbWidth(), height: this.getThumbWidth()/3*2, }} resizeMode="contain" source={imagesrc[4].image} />
    <Image style={{width: this.getThumbWidth(), height: this.getThumbWidth()/3*2, }} resizeMode="contain" source={imagesrc[5].image} />
</View>


</Swiper>

    <View style={{ flexDirection:'row', justifyContent:this.state.autoPlay?'center':'space-between', alignItems:'center', width:'50%', alignSelf:'center' }}>
        { !this.state.autoPlay ?
        <TouchableOpacity style={{  }} onPress={()=>{ this.refs.Swiper.goToPrev(); }}>
            <Icon size={32} name={'arrow-left-thick'} type='material-community' color={MainColor.grayultralight}/>
        </TouchableOpacity>:null
        }
        <TouchableOpacity style={{  }} onPress={()=>{ this.setPlayer() }}>
            <Icon size={32} name={this.state.autoPlay?'pause':'play'} type='material-community' color={MainColor.grayultralight}/>
        </TouchableOpacity>
        { !this.state.autoPlay ?
        <TouchableOpacity style={{  }} onPress={()=>{ this.refs.Swiper.goToNext(); }}>
            <Icon size={32} name={'arrow-right-thick'} type='material-community' color={MainColor.grayultralight}/>
        </TouchableOpacity>:null
        }
    </View>
</View>
);

  }
}

