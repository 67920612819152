import React, { Component } from 'react';



import {
  Dimensions,
  View,
  Text,
  Image,
  TouchableOpacity,

} from 'react-native';


import {appstrings} from './translate/Strings';

import {MainColor, regularFont, blackFont, boldFont, mFontSize } from './Controller';

import Svg, {

  Path,
  LinearGradient,
  Rect,
  Stop,

} from 'react-native-svg';

const pfadOnlyCurve = 'M1079.59,70.7C1030.21,77.6,987.9,81.88,955,84.64c-88,7.38-131,5.5-160.07,3.53-9.23-.62-20.15-1.75-26.27-2.41C743.3,83,692.76,78,628.58,67c0,0-152.61-25.07-268-40.92-5-.69-32.28-4.4-65.2-7-12.88-1-23.62-1.63-33.63-2-2.31-.09-18.17-.72-33.62-.68-12.88,0-31.49.55-68.14,4.07-38.63,3.71-61,5.86-91.82,11.65C49.84,35.52,26.63,40.6,0,48.27V0H1080Q1079.79,35.36,1079.59,70.7Z';


export default class Sicherheit extends Component {

constructor(props) {
    super(props);

    this.state = {
            imageHeight: 200,
    };
  }


componentDidMount(){

}

componentWillUnmount() {

}







getScreenSize(){
    let w = Dimensions.get('window').width;
    let h = Dimensions.get('window').height;
    let isTablet = true;
    if ( h > w ){
        isTablet = false
    }

    return { width: w, height: h, isTablet:isTablet };
}

istEsEineGeradeZahl(value) {
 if (value%2 === 0)
  return true;
   else
  return false;
}

render() {



return (

<View ref={'sicherheitview'} onLayout={(event) => {this.props.onLayout(event)}} style={{ marginTop:50, marginBottom:50, width: '100%',justifyContent:'center', alignItems:'center',  }}>

  <Svg height={this.getScreenSize().isTablet?40:20} width={this.getScreenSize().width} style={{ marginTop:50, marginBottom:0, transform:[{rotate:'180deg'}], opacity:1 }} viewBox="0 0 1080 90" preserveAspectRatio="none">

        <LinearGradient id="gradient_sicherheit1" x1={'0%'} y1={'0%'} x2={'100%'} y2={'0%'}>
                          <Stop offset="0" stopColor={MainColor.verlaufamber50} stopOpacity="1" />
                          <Stop offset="0.5" stopColor={MainColor.verlaufamber50} stopOpacity="1" />
                          <Stop offset="1" stopColor={MainColor.verlaufamber50} stopOpacity="1" />
                       </LinearGradient>

      <Path id="path" fillRule="evenodd" fill="url(#gradient_sicherheit1)" fillOpacity="1" d={pfadOnlyCurve} />
  </Svg>

<View style={{ paddingTop:30, paddingBottom:30, width:'100%', justifyContent:'center', alignItems:'center',  }}>

          <Svg height={'100%'} width={'100%'} style={{ position:'absolute', top:0 }} >

                <LinearGradient id="gradient_sicherheit2" x1={'0%'} y1={'0%'} x2={'100%'} y2={'0%'}>
                                  <Stop offset="0" stopColor={MainColor.verlaufamber50} stopOpacity="1" />
                                  <Stop offset="0.5" stopColor={MainColor.verlaufamber50} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.verlaufamber50} stopOpacity="1" />
                               </LinearGradient>

              <Rect height={'100%'} width={'100%'} fill="url(#gradient_sicherheit2)"/>
          </Svg>

<Text style={{  zIndex:2, fontFamily:boldFont, fontSize:mFontSize.head, width: this.getScreenSize().isTablet?'50%':'80%', color: MainColor.streuwerkgrau, textAlign:'center', marginTop:20,  }}>{appstrings().strings.sec_titel}</Text>
<Text style={{  zIndex:2, fontFamily:regularFont, fontSize:mFontSize.text, width: this.getScreenSize().isTablet?'50%':'80%', color: MainColor.streuwerkgrau, textAlign:'center', marginTop:50,  }}>{appstrings().strings.sec_text}</Text>

<TouchableOpacity style={{ width: this.getScreenSize().isTablet?'50%':'80%' }} onPress={()=>{ this.props.makeAction('Streuwerk') }}>
<Text style={{  zIndex:2, fontFamily:blackFont, fontSize:mFontSize.text, color: MainColor.weiss, textAlign:'center', marginTop:50,  }}>{appstrings().strings.busikd}</Text>
<Text style={{  zIndex:2, fontFamily:regularFont, textDecorationLine: 'underline', fontSize:mFontSize.text, color: MainColor.weiss, textAlign:'center', marginTop:10,  }}>{appstrings().strings.weitereinfos}</Text>
</TouchableOpacity>

    <View style={{ flexDirection:'row', marginTop:50, marginBottom:50 }}>
        <Image style={{ width: this.props.width/4, maxHeight: this.props.width/4*0.4 }} resizeMode="contain" source={require("./images/ssl.png")} />
        <Image style={{ width: this.props.width/4, height: this.props.width/4*0.4 }} resizeMode="contain" source={require("./images/tuevsued.png")} />
        <View style={{ width: this.props.width/5, height: this.props.width/4*0.4, borderRadius:3, overflow:'hidden' }}>
            <View style={{ width: '100%', height: this.props.width/4*0.4/3, backgroundColor:'#000000', justifyContent:'center' }}><Text style={{color:'#ffffff', textAlign:'center',width:'100%',fontSize:this.props.width/4*0.4/3/1.5}}>{appstrings().strings.hundertproz}</Text></View>
            <View style={{ width: '100%', height: this.props.width/4*0.4/3, backgroundColor:'#d10022', justifyContent:'center' }}><Text style={{color:'#ffffff', textAlign:'center',width:'100%',fontSize:this.props.width/4*0.4/3/1.5}}>{appstrings().strings.deutsche}</Text></View>
            <View style={{ width: '100%', height: this.props.width/4*0.4/3, backgroundColor:'#ffd600', justifyContent:'center' }}><Text style={{color:'#ffffff', textAlign:'center',width:'100%',fontSize:this.props.width/4*0.4/3/1.5}}>{appstrings().strings.server}</Text></View>

        </View>
    </View>

</View>
          <Svg height={this.getScreenSize().isTablet?40:20} width={this.getScreenSize().width} style={{ marginTop:-1, transform:[{rotate:'0deg'}], opacity:1 }} viewBox="0 0 1080 90" preserveAspectRatio="none">

                <LinearGradient id="gradient_sicherheit3" x1={'0%'} y1={'0%'} x2={'100%'} y2={'0%'}>
                                  <Stop offset="0" stopColor={MainColor.verlaufamber50} stopOpacity="1" />
                                  <Stop offset="0.5" stopColor={MainColor.verlaufamber50} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.verlaufamber50} stopOpacity="1" />
                               </LinearGradient>

              <Path id="path" fillRule="evenodd" fill="url(#gradient_sicherheit3)" d={pfadOnlyCurve} />
          </Svg>

</View>
);

  }
}

