import React, { Component } from 'react';

import {
    View,
    Dimensions,
    TouchableOpacity,
    Image,
    Text,
    ScrollView,
    Animated,
    Linking,
    StatusBar,

} from 'react-native';

import { createElement } from "react-native-web";

import Impressum from './Impressum';

import MyWebView from './MyWebView';

import Qualitaet from './Qualitaet';
import Sicherheit from './Sicherheit';

import Footer from './Footer';
import FooterMini from './FooterMini';

import { mFontSize, MainColor, businessAnfrage, regularFont, blackFont, startbild, postcard, appData } from './Controller';

import DrawerLayout from './DrawerLayout';

import * as rdd from 'react-device-detect';

import ProduktSliderTablet from './ProduktSliderTablet';
import ProduktSlider from './ProduktSlider';
import Video from './Video';

import {appstrings} from './translate/Strings';

import { Icon } from 'react-native-elements';



import Svg, {

  Path,
  LinearGradient,
  Rect,
  Stop,

} from 'react-native-svg';

const pfadOnlyCurve = 'M1079.59,70.7C1030.21,77.6,987.9,81.88,955,84.64c-88,7.38-131,5.5-160.07,3.53-9.23-.62-20.15-1.75-26.27-2.41C743.3,83,692.76,78,628.58,67c0,0-152.61-25.07-268-40.92-5-.69-32.28-4.4-65.2-7-12.88-1-23.62-1.63-33.63-2-2.31-.09-18.17-.72-33.62-.68-12.88,0-31.49.55-68.14,4.07-38.63,3.71-61,5.86-91.82,11.65C49.84,35.52,26.63,40.6,0,48.27V0H1080Q1079.79,35.36,1079.59,70.7Z';

const MyHeaderHeight = 70;
/*
const attrs = {
  src: require("./images/video.mov"),
  poster: "https://www.urlaubsgruss.com/img/logo-urlaubsgruss-com.png",
  controls: "controls"
};
*/
const impressumurl = 'https://www.urlaubsgruss.com/mobileapps2/legalfiles/de/pa/impressum.html';
const agburl = 'https://www.urlaubsgruss.com/mobileapps2/legalfiles/de/pa/agb.html';
const datenschutzurl = 'https://www.urlaubsgruss.com/mobileapps2/legalfiles/de/pa/datenschutz.html';


export default class Start extends Component {



constructor() {
        super();

        this.openDrawer = this.openDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);

        this.state = {

            showApplication: true,
            isDrawerOpen: false,
            showHeaderShadow: true,
            showVerlauf: false,
            scrollY: new Animated.Value(0),
            scrollY2: new Animated.Value(0),
            posPhilosophie: 0,
            posSicherheit: 0,
            posMarken: 0,
            posColor: 0,
            showLaufzeiten: false,
            showBusiness: false,
            showKooperationen: false,
            showApi: false,
            showImpressum: false,
            showCharity: false,
            showPresse: false,
            showDesign: false,
            showPraesentation: false,
            showPraesentationCode: false,
            showPraesentationCodeTablet: false,
            showWebView: false,
            showMiniFooter: false,

            myWebViewTitel: '',
            myWebViewUrl: '',
            myWebViewShowKontakt: false,
            mobileMenu:false,

            praesentationID: '',


            showMainImage:true,
            isPraesentationEnabled: false,
            showShadow: false,
            miniFooterHeight: 0,

        }

    }


handleScroll(e){

    Animated.event([{nativeEvent: {contentOffset: {y: this.state.scrollY}}}]);
    //Animated.event([{nativeEvent: {contentOffset: {y: this.state.scrollY2}}}]);
    //this.setState({ scrollY: e.nativeEvent.contentOffset.y });

}

handleLayout(event){
    if ( event.nativeEvent.layout.width > (this.getScreenSize().width-((MyHeaderHeight-20)*3.2)-100) ){
            this.setState({ mobileMenu: true });
    } else {
            this.setState({ mobileMenu: false });
    }
    //alert(event.nativeEvent.layout.width)
}

handleLayout2(event){

    let menuLeft = event.nativeEvent.layout.x+((MyHeaderHeight-20)*3.2)-100;
    let menuTop = event.nativeEvent.layout.y;
    let menuWidth = event.nativeEvent.layout.width;

    this.setState({ menuLeft: menuLeft, menuTop: menuTop, menuWidth: menuWidth });
}

componentDidMount() {


let split = window.location.href.split('&');
let data = [];
for(var i = 0; i < split.length; i++) {
    let info = split[i].split('=');
    if ( info[0] === 'show' ){
        data.push({'show':info[1]})
    }
}

setTimeout(() => {
//alert(JSON.stringify(data));
    if ( data.length > 0 ){
        if ( data[0].hasOwnProperty('show') ){
            this.setState({ praesentationID: data[0].show });
            //setTimeout(() => { this.setState({ showPraesentation: true }); }, 10);
        }
    }
}, 1000);
//this.openDrawer();
//this.menuaction('Impressum');
//this.setState({myWebViewTitel:appstrings().strings.impressum, myWebViewUrl:impressumurl, myWebViewShowKontakt: false, showWebView:true, });
//Dimensions.addEventListener('change', this._onDimensionsChange.bind(this));
//let wv = [];
//AsyncStorage.setItem('wiedervorlagen', JSON.stringify(wv));
//AsyncStorage.clear();
}

componentWillUnmount() {
    Dimensions.removeEventListener('change', this._onDimensionsChange.bind(this));
}

_onDimensionsChange(event) {
    this.setState({ showApplication: false });
    setTimeout(() => { this.setState({ showApplication: true }); }, 10);
}

Capitalize(str){
return str.charAt(0).toUpperCase() + str.slice(1);
}




menuaction(action){

this.drawer.closeDrawer();

    if ( this.state.showWebView ){
        if ( action === 'Datenschutz' || action === 'agb' || action === 'Impressum' ){
        } else {
        this.refs.MyWebView.close();
        this.setState({showMiniFooter:false});
     }
    }
    if ( this.state.showBusiness ){
        this.refs.Business.close();
    }
    if ( this.state.showPraesentation ){
        this.refs.Praesentation.close();
    }
    if ( this.state.showKooperationen ){
        this.refs.Kooperationen.close();
    }
    if ( this.state.showPresse ){
        this.refs.Presse.close();
    }

    if ( this.state.showDesign ){
        this.refs.Design.close();
    }
    if ( this.state.showApi ){
        this.refs.Api.close();
    }
    if ( this.state.showCharity ){
        this.refs.Charity.close();
    }
    if ( this.state.showImpressum ){
        this.refs.Impressum.close();
    }

setTimeout(() => {

        this.setState({ showPraesentationCode: false });

        if ( action === "googleApp" ){
                //let url = 'https://play.google.com/store/apps/details?id=de.Postkarte';
                //window.open(url, "_blank");

                Linking.openURL('market://details?id=de.Postkarte');
        }
        if ( action === "appleApp" ){
                //let url = 'https://itunes.apple.com/de/app/id533470794';
                //window.open(url, "_blank");
                Linking.openURL('itms-apps://itunes.apple.com/us/app/apple-store/id533470794?mt=8');
        }
        if ( action === "Anfrage" ){


                   let mail = businessAnfrage.mail;
                   let subject = businessAnfrage.betreff;
                   //let message = appstrings().strings.support1+support.appname+'-Team,\n\n'+appstrings().strings.support4;
                   //let url = 'mailto:'+support.mail+'?cc=&subject='+subject+'&body='+message;
                   let url = 'mailto:'+mail+'?cc=&subject='+subject;
                   Linking.openURL(url);

                //this.checkInternetVerbindung('Support');

        }
        if ( action === 'Streuwerk' ){
            window.open('https://www.streuwerk.com', "_blank");
        }


        if ( action === 'Start' ){
            this.refs.ScrollMain.scrollTo({x: 0, y: 0, animated: true});
        }

        if ( action === 'UnsereApps' ){
            this.refs.ScrollMain.scrollTo({x: 0, y: this.state.posMarken-20, animated: true});
        }

        if ( action === 'Philosophie' ){
            this.refs.ScrollMain.scrollTo({x: 0, y: this.getScreenSize().isTablet?this.state.posPhilosophie-20:this.state.posPhilosophie+20, animated: true});
        }
        if ( action === 'Sicherheit' ){
            this.refs.ScrollMain.scrollTo({x: 0, y: this.getScreenSize().isTablet?this.state.posSicherheit+90:this.state.posSicherheit+80, animated: true});
        }

        if ( action === "agb" ){
                this.setState({myWebViewTitel:appstrings().strings.agb, myWebViewUrl:agburl, myWebViewShowKontakt: false, showWebView:true, showMiniFooter:true, });
        }
        if ( action === "Datenschutz" ){
                this.setState({myWebViewTitel:appstrings().strings.datenschutz, myWebViewUrl:datenschutzurl, myWebViewShowKontakt: false, showWebView:true, showMiniFooter:true });
                //this.setState({ showCookie: true });
        }
        if ( action === "Impressum" ){
                this.setState({myWebViewTitel:appstrings().strings.impressum, myWebViewUrl:impressumurl, myWebViewShowKontakt: false, showWebView:true, showMiniFooter:true });
                //this.setState({ showImpressum: true });
        }

}, this.state.isDrawerOpen?1000:10);

}




getScreenSize(){
    let w = Dimensions.get('window').width;
    let h = Dimensions.get('window').height;
    let isTablet = true;
    if ( h > w ){
        isTablet = false
    }

    return { width: w, height: h, isTablet:isTablet };
}

openDrawer() {
        this.drawer.openDrawer();
        //NativeModules.DevMenu.show();
}
closeDrawer() {
        this.drawer.closeDrawer();
}

getDrawerWidth(){
    var drawerWidth = this.getScreenSize().width/5*4;
    if ( this.getScreenSize().isTablet ){
        drawerWidth = this.getScreenSize().width/5*1.5;
    }
    return drawerWidth;
}

openAppStore(platform,name){
/*
    let deviceInfo = { 'isMacOs': rdd.isMacOs, 'isWindows': rdd.isWindows, 'isAndroid': rdd.isAndroid, 'isWinPhone': rdd.isWinPhone, 'isIOS': rdd.isIOS,
                       'osName': rdd.osName, 'osVersion': rdd.osVersion, 'browserName': rdd.browserName, 'browserVersion': rdd.browserVersion,
                       'mobileModel': rdd.mobileModel, 'deviceType': rdd.deviceType };
                       alert(JSON.stringify(deviceInfo))
  */

                if ( platform === 'android' ){

                    if ( rdd.isAndroid ){

                        Linking.openURL(appData[name].android);

                    } else {

                        window.open(appData[name].androidweb, "_blank");

                    }

                } else if ( platform === 'ios' ){

                    if ( rdd.isIOS ){

                        Linking.openURL(appData[name].ios);

                    } else {

                        window.open(appData[name].iosweb, "_blank");

                    }

                } else {

                    window.open(appData[name].www, "_blank");
                }



}


getRightBtn(){

    let btn = [{'iconName':'menu','onPress':'onPressRight1'}];
/*
    if ( this.state.showBusiness ){
        btn = [{'iconName':'arrow-left','onPress':'onPressRightBusinessBack'}];
    }

    if ( this.state.showKooperationen ){
        btn = [{'iconName':'arrow-left','onPress':'onPressRightKooperationenBack'}];
    }
    if ( this.state.showPresse ){
        btn = [{'iconName':'arrow-left','onPress':'onPressRightPresseBack'}];
    }
    if ( this.state.showDesign ){
        btn = [{'iconName':'arrow-left','onPress':'onPressRightDesignBack'}];
    }

    if ( this.state.showPraesentation ){
        btn = [{'iconName':'arrow-left','onPress':'onPressRightPraesentationBack'}];
    }
    if ( this.state.showApi ){
        btn = [{'iconName':'arrow-left','onPress':'onPressRightApiBack'}];
    }
    if ( this.state.showCharity ){
        btn = [{'iconName':'arrow-left','onPress':'onPressRightCharityBack'}];
    }

    if ( this.state.showWebView ){
        btn = [{'iconName':'arrow-left','onPress':'onPressRightWebViewBack'}];
    }
*/
return btn;
}




getHeaderPlusHeight(){

let height = 50;
if ( this.state.showWebView || this.state.showBusiness || this.state.showPraesentation || this.state.showKooperationen || this.state.showPresse || this.state.showDesign || this.state.showApi || this.state.showCharity || this.state.showImpressum ){
    height = 0;
}
return height;
}
getHeaderPlusScale(){

let scale = this.getScreenSize().isTablet?1.5:0.75;
if ( this.state.showWebView || this.state.showBusiness || this.state.showPraesentation || this.state.showKooperationen || this.state.showPresse || this.state.showDesign || this.state.showApi || this.state.showCharity || this.state.showImpressum ){
    scale = this.getScreenSize().isTablet?1:0.5;
}
return scale;
}

  createVideo(attrs) {
    return createElement("video", attrs);
  }


getTitelHeight(){

return this.getScreenSize().isTablet?this.getScreenSize().height-this.getHeaderPlusHeight()-MyHeaderHeight+30:(this.getScreenSize().width/3*2*2)+50+(this.getScreenSize().width/3*0.3)+50;

}




  render() {




var navigationView2 = (
              <View style={{flex: 1, }}>

          <Svg height={'100%'} width={'100%'} style={{ position:'absolute', top:0 }} >

                <LinearGradient id="gradient_menu" x1={'0%'} y1={'0%'} x2={'40%'} y2={'100%'}>
                                  <Stop offset="0" stopColor={MainColor.verlaufamber50} stopOpacity="1" />
                                  <Stop offset="0.75" stopColor={MainColor.verlaufamber100} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.verlaufamber200} stopOpacity="1" />
                               </LinearGradient>

              <Rect height={'100%'} width={'100%'} fill="url(#gradient_menu)"/>
          </Svg>

               <ScrollView contentContainerStyle={{ marginTop:80 }}>

                        <TouchableOpacity activeOpacity={0.95} style={{ paddingLeft:30, flexDirection:'row', alignItems:'center', height:60, borderBottomWidth:0,  }} onPress={() => this.menuaction('Start')}>
                            <Icon size={mFontSize.icon} name='home' type='material-community' color={MainColor.menuIconColor}/>
                            <Text style={{ fontFamily:regularFont, color:MainColor.menuTextColor, marginLeft:25, fontSize:mFontSize.menu, }}>{appstrings().strings.start}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity activeOpacity={0.95} style={{ paddingLeft:30, flexDirection:'row', alignItems:'center', height:60, borderBottomWidth:0,  }} onPress={() => this.menuaction('UnsereApps')}>
                            <Icon size={mFontSize.icon} name='cellphone-screenshot' type='material-community' color={MainColor.menuIconColor}/>
                            <Text style={{ fontFamily:regularFont, color:MainColor.menuTextColor, marginLeft:25, fontSize:mFontSize.menu, }}>{appstrings().strings.uberuns}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity activeOpacity={0.95} style={{ paddingLeft:30, flexDirection:'row', alignItems:'center', height:60, borderBottomWidth:0,  }} onPress={() => this.menuaction('Philosophie')}>
                            <Icon size={mFontSize.icon} name='sprout' type='material-community' color={MainColor.menuIconColor}/>
                            <Text style={{ fontFamily:regularFont, color:MainColor.menuTextColor, marginLeft:25, fontSize:mFontSize.menu, }}>{appstrings().strings.philosopie}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity activeOpacity={0.95} style={{ paddingLeft:30, flexDirection:'row', alignItems:'center', height:60, borderBottomWidth:0,  }} onPress={() => this.menuaction('Sicherheit')}>
                            <Icon size={mFontSize.icon} name='lock' type='material-community' color={MainColor.menuIconColor}/>
                            <Text style={{ fontFamily:regularFont, color:MainColor.menuTextColor, marginLeft:25, fontSize:mFontSize.menu, }}>{appstrings().strings.sicherheit}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity activeOpacity={0.95} style={{ paddingLeft:30, flexDirection:'row', alignItems:'center', height:60, borderBottomWidth:0,  }} onPress={() => this.menuaction('Datenschutz')}>
                            <Icon size={mFontSize.icon} name='life-ring' type='font-awesome' color={MainColor.menuIconColor}/>
                            <Text style={{fontFamily:regularFont, color:MainColor.menuTextColor, marginLeft:25, fontSize:mFontSize.menu, }}>{appstrings().strings.agb}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity activeOpacity={0.95} style={{ paddingLeft:30, flexDirection:'row', alignItems:'center', height:60, borderBottomWidth:0,  }} onPress={() => this.menuaction('Datenschutz')}>
                            <Icon size={mFontSize.icon} name='lock-question' type='material-community' color={MainColor.menuIconColor}/>
                            <Text style={{fontFamily:regularFont, color:MainColor.menuTextColor, marginLeft:25, fontSize:mFontSize.menu, }}>{appstrings().strings.datenschutz}</Text>
                        </TouchableOpacity>

                      <TouchableOpacity activeOpacity={0.95} style={{ paddingLeft:30, marginBottom:150, flexDirection:'row', alignItems:'center', height:60, borderBottomWidth:0,  }} onPress={() => this.menuaction('Impressum')}>
                            <Icon size={mFontSize.icon} name='script-text-outline' type='material-community' color={MainColor.menuIconColor}/>
                            <Text style={{fontFamily:regularFont, color:MainColor.menuTextColor, marginLeft:25, fontSize:mFontSize.menu, }}>{appstrings().strings.impressum}</Text>
                        </TouchableOpacity>


               </ScrollView>

        <TouchableOpacity style={{ position:'absolute', top:20, right:30,  }} onPress={()=>{ this.closeDrawer(this) }}>
            <Icon size={32} name={'close'} type='material-community' color={MainColor.menuTextColor}/>
        </TouchableOpacity>


              </View>
        );


  const mAnimateColor = this.state.scrollY.interpolate({
    inputRange: [0, this.getTitelHeight()*0.25, this.getTitelHeight()*0.5, this.getTitelHeight()*0.75, this.getTitelHeight()-50],
    outputRange: [MainColor.rosa+'50', MainColor.rosa, MainColor.hellblau, MainColor.hellblau+'50', MainColor.weiss],
    extrapolate: 'clamp',
    useNativeDriver: true
  });

  const mAnimateShadowOpacity = this.state.scrollY.interpolate({
    inputRange: [0, 50],
    outputRange: [0, 9],
    extrapolate: 'clamp',
    useNativeDriver: true
  });

  const mHeight = this.state.scrollY.interpolate({
    inputRange: [0, 150],
    outputRange: [MyHeaderHeight+this.getHeaderPlusHeight(), MyHeaderHeight],
    extrapolate: 'clamp',
    useNativeDriver: true
  });

  const mScale = this.state.scrollY.interpolate({
    inputRange: [0, 150],
    outputRange: [this.getHeaderPlusScale(), this.getScreenSize().isTablet?1:0.5],
    extrapolate: 'clamp',
    useNativeDriver: true
  });

  const mAnimateLeftText = this.state.scrollY.interpolate({
    inputRange: [0, 150],
    outputRange: [this.getScreenSize().isTablet?30:0, this.getScreenSize().isTablet?10:-10],
    extrapolate: 'clamp',
    useNativeDriver: true
  });

  const mAnimateLeftLogo = this.state.scrollY.interpolate({
    inputRange: [0, 150],
    outputRange: [this.getScreenSize().isTablet?100:10, this.getScreenSize().isTablet?50:10],
    extrapolate: 'clamp',
    useNativeDriver: true
  });

    return (

<>
  <style type="text/css">{`
    @font-face {
      font-family: 'FontAwesome';
      src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'MaterialCommunityIcons';
      src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
    }
  `}</style>

<DrawerLayout
      drawerWidth={this.getDrawerWidth()}
      ref={(_drawer) => this.drawer = _drawer}
      drawerPosition={DrawerLayout.positions.Right}
      onDrawerClose={() => this.setState({isDrawerOpen: false })}
      onDrawerOpen={() => this.setState({isDrawerOpen: true }) }
      renderNavigationView={() => navigationView2}>
{ this.state.showApplication ?
<View style={{ position:'absolute', width:this.getScreenSize().width, height:this.getScreenSize().height, backgroundColor:'#ffffff'}}>


{
this.getScreenSize().isTablet===false || this.state.mobileMenu ?
<Animated.View style={{ zIndex:mAnimateShadowOpacity, width: this.getScreenSize().width, height:mHeight, backgroundColor:mAnimateColor, borderColor:MainColor.grayultralight, borderTopWidth:0, borderBottomWidth:0,
                shadowColor: MainColor.schwarz, shadowOffset: { width: 0, height: 3 }, shadowRadius: 5, shadowOpacity: 0.1, alignItems:'center', justifyContent:'center'}}>

<StatusBar translucent={true} hidden={true} backgroundColor={MainColor.weiss} barStyle={'light-content'} />

    <TouchableOpacity style={{ position:'absolute', left:0, flexDirection:'row', alignItems:'center' }} onPress={()=>{ this.menuaction('Start')}}>
    <Animated.Image style={{ marginLeft:mAnimateLeftLogo, width: (MyHeaderHeight-20)/0.658, height: MyHeaderHeight-20, transform:[{scale:mScale}] }} resizeMode="contain" source={require("./images/postkartenapp-logo.png")}/>
    <Animated.Text style={{ marginLeft: mAnimateLeftText, fontFamily:blackFont, color: MainColor.streuwerkgrau, fontSize: this.getScreenSize().isTablet?30:20, }}>{appstrings().strings.titel}</Animated.Text>
    </TouchableOpacity>

    <TouchableOpacity style={{ position:'absolute', right:30 }} onPress={()=>{ this.openDrawer(this) }}>
        <Icon size={54} name={'menu'} type='material-community' color={MainColor.streuwerkgrau} />
    </TouchableOpacity>


<View onLayout={(event) => {this.handleLayout(event)}} style={{ flexDirection:'row', position:'absolute', right:50, bottom:10,   }}>



    </View>

</Animated.View>:
<Animated.View style={{ zIndex:mAnimateShadowOpacity, width: this.getScreenSize().width, height:mHeight, backgroundColor:mAnimateColor, borderColor:MainColor.grayultralight, borderTopWidth:0, borderBottomWidth:0,
                shadowColor: MainColor.schwarz, shadowOffset: { width: 0, height: 3 }, shadowRadius: 5, shadowOpacity: 0.1, alignItems:'center', justifyContent:'center'}}>

    <TouchableOpacity style={{ position:'absolute', left:0, flexDirection:'row' }} onPress={()=>{ this.menuaction('Start')}}>
    <Animated.Image style={{ marginLeft:mAnimateLeftLogo, width: (MyHeaderHeight-20)/0.658, height: MyHeaderHeight-20, transform:[{scale:mScale}] }} resizeMode="contain" source={require("./images/postkartenapp-logo.png")}/>
    <Animated.Text style={{ marginLeft: mAnimateLeftText, fontFamily:blackFont, color: MainColor.streuwerkgrau, fontSize: this.getScreenSize().isTablet?30:20, }}>{appstrings().strings.titel}</Animated.Text>
    </TouchableOpacity>


<View onLayout={(event) => {this.handleLayout(event)}} style={{ flexDirection:'row', position:'absolute', right:50, bottom:10,   }}>

    <Text ref={'menu1'} onPress={()=>{ this.menuaction('Start') }}
          onMouseOver={()=>{ this.refs.menu1.setNativeProps({style: { color: MainColor.primary, borderColor: MainColor.primary  }}); }}
          onMouseLeave={()=>{ this.refs.menu1.setNativeProps({style: { color: MainColor.streuwerkgrau, borderColor: MainColor.transparent }}); }}
          style={{ fontFamily:blackFont, color: MainColor.streuwerkgrau, fontSize: mFontSize.headermenu, textAlign:'center',
                   borderColor:MainColor.transparent, borderBottomWidth: 3, paddingBottom:5}} >
    {appstrings().strings.start}</Text>


    <Text ref={'menu2'} onPress={()=>{ this.menuaction('UnsereApps') }}
          onMouseOver={()=>{ this.refs.menu2.setNativeProps({style: { color: MainColor.primary, borderColor: MainColor.primary }}); }}
          onMouseLeave={()=>{ this.refs.menu2.setNativeProps({style: { color: MainColor.streuwerkgrau, borderColor: MainColor.transparent }}); }}
          style={{ fontFamily:blackFont, color: MainColor.streuwerkgrau, fontSize: mFontSize.headermenu, textAlign:'center', marginLeft:50,
                    borderColor:MainColor.transparent, borderBottomWidth: 3, paddingBottom:5}} >
    {appstrings().strings.uberuns}</Text>

    <Text ref={'menu3'} onPress={()=>{ this.menuaction('Sicherheit') }}
          onMouseOver={()=>{ this.refs.menu3.setNativeProps({style: { color: MainColor.primary, borderColor: MainColor.primary }}); }}
          onMouseLeave={()=>{ this.refs.menu3.setNativeProps({style: { color: MainColor.streuwerkgrau, borderColor: MainColor.transparent }}); }}
          style={{ fontFamily:blackFont, color: MainColor.streuwerkgrau, fontSize: mFontSize.headermenu, textAlign:'center', marginLeft:50,
                    borderColor:MainColor.transparent, borderBottomWidth: 3, paddingBottom:5}} >
    {appstrings().strings.sicherheit}</Text>

    <Text ref={'menu4'} onPress={()=>{ this.menuaction('Philosophie') }}
          onMouseOver={()=>{ this.refs.menu4.setNativeProps({style: { color: MainColor.primary, borderColor: MainColor.primary }}); }}
          onMouseLeave={()=>{ this.refs.menu4.setNativeProps({style: { color: MainColor.streuwerkgrau, borderColor: MainColor.transparent }}); }}
          style={{ fontFamily:blackFont, color: MainColor.streuwerkgrau, fontSize: mFontSize.headermenu, textAlign:'center', marginLeft:50,
                    borderColor:MainColor.transparent, borderBottomWidth: 3, paddingBottom:5}} >
    {appstrings().strings.philosopie}</Text>

    </View>

</Animated.View>
}

{ !this.state.showShadow ?
<Image pointerEvents={'none'} style={{ zIndex:9, position:'absolute', top:0, left:0, width:'100%', height:'100%', opacity:0.2 }} resizeMode="stretch" source={require('./shadows/shadow12.png')} />:null
}

<ScrollView ref={'ScrollMain'} scrollEventThrottle={16} onScroll={Animated.event([{nativeEvent: {contentOffset: {y: this.state.scrollY}}}])} style={{ width:'100%', backgroundColor:MainColor.weiss }} contentContainerStyle={{ alignItems:'center'}}>


<Animated.View style={{ width:'100%', height:this.getTitelHeight(), backgroundColor:mAnimateColor }}>

{ this.getScreenSize().isTablet ?
    <Image style={{ position:'absolute', top:0, right:this.getScreenSize().width/20, width:this.getScreenSize().width/2, height:this.getScreenSize().width/2/3*2, }} resizeMode="contain" source={startbild} />:
    <Image style={{ marginTop:0, width:this.getScreenSize().width-20, height:(this.getScreenSize().width-20)/3*2, alignSelf:'center' }} resizeMode="contain" source={startbild} />
}
{ this.getScreenSize().isTablet ?
    <Image style={{ position:'absolute', bottom:50, left:this.getScreenSize().width/20, width:this.getScreenSize().width/1.75, height:this.getScreenSize().width/1.75/3*2, }} resizeMode="contain" source={postcard} />:
    <Image style={{ position:'absolute', bottom:100+(this.getScreenSize().width/3*0.3), width:this.getScreenSize().width-20, height:(this.getScreenSize().width-20)/3*2, alignSelf:'center' }} resizeMode="contain" source={postcard} />
}
{ this.getScreenSize().isTablet ?
    <Image style={{ position:'absolute', top:0, left:20, width:this.getScreenSize().width/5, height:this.getScreenSize().width/5*0.76, }} resizeMode="contain" source={require('./images/einfach.png')} />:
    <Image style={{ position:'absolute', top:(this.getScreenSize().width/3*2)-50, left:20, width:this.getScreenSize().width/2.5, height:this.getScreenSize().width/2.5*0.76, }} resizeMode="contain" source={require('./images/einfach.png')} />
}

{ this.getScreenSize().isTablet ?
<View style={{ flexDirection:'row', position:'absolute', bottom:100, right:this.getScreenSize().width/20, width: this.getScreenSize().width/7*2+30, justifyContent:'space-between' }}>
    <TouchableOpacity style={{  }} onPress={()=>{this.openAppStore('android','pa')}}>
        <Image style={{ width:this.getScreenSize().width/7, height:this.getScreenSize().width/7*0.3, }} resizeMode="contain" source={require('./images/webseite/googleplaybadge_de.png')}/>
    </TouchableOpacity>

    <TouchableOpacity style={{  }} onPress={()=>{this.openAppStore('ios','pa')}}>
        <Image style={{ width:this.getScreenSize().width/7, height:this.getScreenSize().width/7*0.3, }} resizeMode="contain" source={require('./images/webseite/iosbadge_de.png')}/>
    </TouchableOpacity>
</View>:
<View style={{ flexDirection:'row', position:'absolute', bottom:70, width: '100%', alignItems:'center', justifyContent:'space-between' }}>

{ rdd.isAndroid ?
    <TouchableOpacity style={{  }} onPress={()=>{this.openAppStore('android','pa')}}>
        <Image style={{ marginLeft:this.getScreenSize().width/4, width:this.getScreenSize().width/2, height:this.getScreenSize().width/2*0.3, }} resizeMode="contain" source={require('./images/webseite/googleplaybadge_de.png')}/>
    </TouchableOpacity>:null
}
{ rdd.isIOS ?
    <TouchableOpacity style={{   }} onPress={()=>{this.openAppStore('ios','pa')}}>
        <Image style={{ marginLeft:this.getScreenSize().width/4, width:this.getScreenSize().width/2, height:this.getScreenSize().width/2*0.3,  }} resizeMode="contain" source={require('./images/webseite/iosbadge_de.png')}/>
    </TouchableOpacity>:null
}
{ rdd.isIOS===false && rdd.isAndroid===false ?
<View style={{ flexDirection:'row', position:'absolute', bottom:0, width:'75%', left:'12.5%', justifyContent:'space-between' }}>
    <TouchableOpacity style={{  }} onPress={()=>{this.openAppStore('android','pa')}}>
        <Image style={{ width:this.getScreenSize().width/3, height:this.getScreenSize().width/3*0.3, }} resizeMode="contain" source={require('./images/webseite/googleplaybadge_de.png')}/>
    </TouchableOpacity>

    <TouchableOpacity style={{  }} onPress={()=>{this.openAppStore('ios','pa')}}>
        <Image style={{ width:this.getScreenSize().width/3, height:this.getScreenSize().width/3*0.3, }} resizeMode="contain" source={require('./images/webseite/iosbadge_de.png')}/>
    </TouchableOpacity>
</View>:null
}

</View>
}



</Animated.View>

<View style={{ marginTop:this.getScreenSize().isTablet?-30:-20, marginBottom:0 }}>
          <Svg height={this.getScreenSize().isTablet?30:20} width={this.getScreenSize().width} style={{ marginBottom:0, transform:[{rotate:'180deg'}],  }} viewBox="0 0 1080 90" preserveAspectRatio="none">

                <LinearGradient id="gradient32a" x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
                                  <Stop offset="0" stopColor={MainColor.weiss} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.weiss} stopOpacity="1" />
                               </LinearGradient>

              <Path id="path" fillRule="evenodd" fill="url(#gradient32a)" fillOpacity="1" d={pfadOnlyCurve} />
          </Svg>

          <Svg height={this.getScreenSize().isTablet?30:20} width={this.getScreenSize().width} style={{ marginTop:0, transform:[{rotate:'0deg'}],  }} viewBox="0 0 1080 90" preserveAspectRatio="none">

                <LinearGradient id="gradient32b" x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
                                  <Stop offset="0" stopColor={MainColor.weiss} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.weiss} stopOpacity="1" />
                               </LinearGradient>

              <Path id="path" fillRule="evenodd" fill="url(#gradient32b)" fillOpacity="1" d={pfadOnlyCurve} />
          </Svg>
</View>


<View style={{ width: this.getScreenSize().isTablet?'50%':'80%', justifyContent:'center', alignItems:'center', marginTop:20, marginBottom:50 }}>

    <Text style={{ fontFamily:blackFont, fontSize:mFontSize.bighead, color: MainColor.signal, textAlign:'center' }}>{appstrings().strings.start_titel}</Text>

</View>

{
this.getScreenSize().isTablet?
<ProduktSliderTablet/>:
<ProduktSlider/>
}


<View style={{ marginTop:50, marginBottom:0 }}>
          <Svg height={this.getScreenSize().isTablet?30:20} width={this.getScreenSize().width} style={{ marginBottom:0, transform:[{rotate:'180deg'}],  }} viewBox="0 0 1080 90" preserveAspectRatio="none">

                <LinearGradient id="gradient33a" x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
                                  <Stop offset="0" stopColor={MainColor.hellblau} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.hellblau} stopOpacity="1" />
                </LinearGradient>

              <Path id="path" fillRule="evenodd" fill="url(#gradient33a)" fillOpacity="1" d={pfadOnlyCurve} />
          </Svg>
<View style={{ marginTop:-1, width:this.getScreenSize().width, alignSelf:'center', justifyContent:'center', alignItems:'center', paddingTop:50, paddingBottom:50 }}>

          <Svg height={'100%'} width={'100%'} style={{ position:'absolute'}}>

                <LinearGradient id="gradient34c" x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
                                  <Stop offset="0" stopColor={MainColor.hellblau} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.rosa} stopOpacity="1" />
                </LinearGradient>

              <Rect height={'100%'} width={'100%'} fill="url(#gradient34c)" />
          </Svg>
    <Text style={{ zIndex:5, width: this.getScreenSize().isTablet?'50%':'85%', padding:50, fontFamily:regularFont, fontSize:mFontSize.subhead, color: MainColor.streuwerkgrau, textAlign:'center', textShadowColor:'#000000', textShadowOffset:{ width:0, height:0 }, textShadowRadius:0,
     backgroundColor:'#ffffff99', borderRadius:25, shadowColor: MainColor.schwarz, shadowOffset: { width: 0, height: 3 }, shadowRadius: 5, shadowOpacity: 0.1, }}>{appstrings().strings.start_text}</Text>

</View>
          <Svg height={this.getScreenSize().isTablet?30:20} width={this.getScreenSize().width} style={{ marginTop:0, transform:[{rotate:'0deg'}],  }} viewBox="0 0 1080 90" preserveAspectRatio="none">

                <LinearGradient id="gradient34a" x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
                                  <Stop offset="0" stopColor={MainColor.rosa} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.rosa} stopOpacity="1" />
                </LinearGradient>

              <Path id="path" fillRule="evenodd" fill="url(#gradient34a)" fillOpacity="1" d={pfadOnlyCurve} />
          </Svg>
</View>





<View style={{ width: this.getScreenSize().isTablet?'50%':'80%', justifyContent:'center', alignItems:'center', marginTop:50, marginBottom:0 }}>

    <Text style={{ fontFamily:blackFont, fontSize:mFontSize.subhead, color: MainColor.streuwerkgrau, textAlign:'center' }}>{appstrings().strings.sofunzt}</Text>

</View>

<View style={{ flexDirection:this.getScreenSize().isTablet?'row':'column', marginTop:50, marginBottom:this.getScreenSize().isTablet?100:50, justifyContent:this.getScreenSize().isTablet?'space-between':'center', alignItems:this.getScreenSize().isTablet?'baseline':'center', width:'80%' }}>

    <View style={{ paddingTop:50, flexDirection:this.getScreenSize().isTablet?'column':'row', marginBottom:this.getScreenSize().isTablet?0:50, borderWidth:0, borderColor:MainColor.hellblau, width:this.getScreenSize().isTablet?'25%':'100%', alignItems:'center',}}>
        <Icon containerStyle={{ position:'absolute', top:-10, right:this.getScreenSize().isTablet?null:0 }} size={64} name='numeric-1-circle-outline' type='material-community' color={MainColor.hellblau}/>
        <Image style={{ width: this.getScreenSize().isTablet?this.getScreenSize().width*0.2:this.getScreenSize().width*0.3, height: this.getScreenSize().isTablet?this.getScreenSize().width*0.2:this.getScreenSize().width*0.3, marginTop:0,  }}
                       resizeMode="contain" source={require("./images/bilderauswahl.png")} />
        <Text style={{ marginTop:this.getScreenSize().isTablet?20:0, marginLeft:this.getScreenSize().isTablet?0:20, fontFamily:regularFont, fontSize:mFontSize.text, color: MainColor.streuwerkgrau, textAlign:'left' }}>{appstrings().strings.schritt1}</Text>
    </View>
    <View style={{ paddingTop:50, flexDirection:this.getScreenSize().isTablet?'column':'row', marginBottom:this.getScreenSize().isTablet?0:50, borderWidth:0, borderColor:MainColor.rosa, width:this.getScreenSize().isTablet?'25%':'100%', alignItems:'center'}}>
        <Icon containerStyle={{ position:'absolute', top:-20, right:this.getScreenSize().isTablet?null:0 }} size={64} name='numeric-2-circle-outline' type='material-community' color={MainColor.rosa}/>
        <Image style={{ width: this.getScreenSize().isTablet?this.getScreenSize().width*0.2:this.getScreenSize().width*0.3, height: this.getScreenSize().isTablet?this.getScreenSize().width*0.2:this.getScreenSize().width*0.3, marginTop:0,  }}
                       resizeMode="contain" source={require("./images/texteingabe.png")} />
        <Text style={{ marginTop:this.getScreenSize().isTablet?20:0, marginLeft:this.getScreenSize().isTablet?0:20, fontFamily:regularFont, fontSize:mFontSize.text, color: MainColor.streuwerkgrau, textAlign:'left' }}>{appstrings().strings.schritt2}</Text>
    </View>
    <View style={{ paddingTop:50, flexDirection:this.getScreenSize().isTablet?'column':'row', marginBottom:this.getScreenSize().isTablet?0:50, borderWidth:0, borderColor:MainColor.hellgrun, width:this.getScreenSize().isTablet?'25%':'100%', alignItems:'center'}}>
        <Icon containerStyle={{ position:'absolute', top:-20, right:this.getScreenSize().isTablet?null:0 }} size={64} name='numeric-3-circle-outline' type='material-community' color={MainColor.hellgrun}/>
        <Image style={{ width: this.getScreenSize().isTablet?this.getScreenSize().width*0.2:this.getScreenSize().width*0.3, height: this.getScreenSize().isTablet?this.getScreenSize().width*0.2:this.getScreenSize().width*0.3, marginTop:0,  }}
                       resizeMode="contain" source={require("./images/versand.png")} />
        <Text style={{ marginTop:this.getScreenSize().isTablet?20:0, marginLeft:this.getScreenSize().isTablet?0:20, fontFamily:regularFont, fontSize:mFontSize.text, color: MainColor.streuwerkgrau, textAlign:'left' }}>{appstrings().strings.schritt3}</Text>
    </View>
</View>










<View style={{ marginTop:50, marginBottom:50 }} onLayout={(event) => {this.setState({ posPhilosophie:event.nativeEvent.layout.y })}}>
          <Svg height={this.getScreenSize().isTablet?30:20} width={this.getScreenSize().width} style={{ marginBottom:0, transform:[{rotate:'180deg'}],  }} viewBox="0 0 1080 90" preserveAspectRatio="none">

                <LinearGradient id="gradient33a" x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
                                  <Stop offset="0" stopColor={MainColor.hellblau} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.hellblau} stopOpacity="1" />
                </LinearGradient>

              <Path id="path" fillRule="evenodd" fill="url(#gradient33a)" fillOpacity="1" d={pfadOnlyCurve} />
          </Svg>

    <Qualitaet
        //color={textColor}
        isTablet={this.getScreenSize().isTablet}
        onLayout={(event) => { }}
        makeAction={(action)=>{ this.menuaction(action) }}
    >
    </Qualitaet>

          <Svg height={this.getScreenSize().isTablet?30:20} width={this.getScreenSize().width} style={{ marginTop:-1, marginBottom:0, transform:[{rotate:'0deg'}],  }} viewBox="0 0 1080 90" preserveAspectRatio="none">

                <LinearGradient id="gradient34a" x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
                                  <Stop offset="0" stopColor={MainColor.rosa} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.rosa} stopOpacity="1" />
                </LinearGradient>

              <Path id="path" fillRule="evenodd" fill="url(#gradient34a)" fillOpacity="1" d={pfadOnlyCurve} />
          </Svg>
</View>



<Sicherheit
    isTablet={this.getScreenSize().isTablet}
    width={this.getScreenSize().isTablet?this.getScreenSize().width/10*6:this.getScreenSize().width/10*8}
    onLayout={(event) => {this.setState({ posSicherheit:event.nativeEvent.layout.y })}}
    makeAction={(action)=>{ this.menuaction(action) }}
/>




<Video
        style={{ borderRadius:20, overflow:'hidden', width: this.getScreenSize().isTablet?this.getScreenSize().width/2:this.getScreenSize().width/3*2, height: this.getScreenSize().isTablet?this.getScreenSize().width/2/3*2:this.getScreenSize().width/3*2/3*2, marginBottom:100 }}
        url={'https://www.youtube.com/embed/piQE042e-8s?&autohide=1'}
        onLayout={(event) => {this.setState({ posMarken:event.nativeEvent.layout.y })}}
    >
</Video>







<Footer
    makeAction={(action)=>{ this.menuaction(action) }}

/>




</ScrollView>

{
this.state.showWebView ?
    <MyWebView
        ref={'MyWebView'}
        onClose={()=>this.setState({showWebView:false, showMiniFooter:false})}
        style={{ width: this.getScreenSize().width, backgroundColor:'#ffffff', height: this.getScreenSize().height-MyHeaderHeight, position:'absolute', top:MyHeaderHeight }}
        titel={this.state.myWebViewTitel}
        miniFooterHeight={this.state.miniFooterHeight}
        url={this.state.myWebViewUrl}
        showKontakt={false}
        makeAction={(action)=>{ this.menuaction(action) }}

    >
    </MyWebView>:null
}
{
this.state.showMiniFooter ?
    <FooterMini
    makeAction={(action)=>{ this.menuaction(action) }}
    onLayout={(event) => {this.setState({ miniFooterHeight:event.nativeEvent.layout.height })}}
    >
    </FooterMini>:null
}
{
this.state.showImpressum ?
<Impressum
    ref={'Impressum'}
    makeAction={(action)=>{ this.menuaction(action) }}
    style={{ width: this.getScreenSize().width, height: this.getScreenSize().height-MyHeaderHeight, position:'absolute', top:MyHeaderHeight }}
    onClose={()=>{ this.setState({ showImpressum: false }) }}/>:null
}
{
this.state.showCookie ?
    <View style={{position:'absolute', width:'100%', height: '100%', zIndex:99999, backgroundColor:'#00000099', justifyContent:'center', alignItems:'center'}}>
        <View style={{ width:this.getScreenSize().isTablet?'40%':'75%', justifyContent:'center', alignItems:'center', backgroundColor:MainColor.weiss, borderRadius: 25}}>
<Image style={{width: this.getScreenSize().isTablet?this.getScreenSize().width/10:this.getScreenSize().width/5, height: this.getScreenSize().isTablet?this.getScreenSize().width/10*0.825:this.getScreenSize().width/5*0.825, marginTop:20 }} resizeMode="contain" source={require("./images/cookie.jpg")} />
                 <Text style={{ width:'80%', marginTop:10, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'center', color:MainColor.streuwerkgrau,  }} >
                       {appstrings().strings.cookies}</Text>
                <TouchableOpacity activeOpacity={0.95} onPress={()=>{ this.setState({ showCookie:false })}}
                          style={{ backgroundColor:MainColor.green, borderRadius: 30, alignItems:'center', justifyContent:'center', marginTop:30, marginBottom:30 }}>
                          <Text style={{fontFamily:regularFont, fontSize:mFontSize.button, color:'#ffffff', fontWeight:'bold', padding:10, paddingLeft:30, paddingRight:30, textAlign:'center' }}>
                          {appstrings().strings.close}</Text>
                </TouchableOpacity>
        </View>
    </View>:null
}

</View>:null
}

</DrawerLayout>


</>
    );
  }
}

/*

<View style={{ justifyContent:'center', alignItems:'center', marginTop:50, marginBottom:50 }}>

<View style={{ position:'absolute', backgroundColor:'#ffffff', width: this.getScreenSize().height/3*2*0.457, height: this.getScreenSize().height/3*2}}>{this.createVideo(attrs)}</View>

<Image pointerEvents={'none'} style={{ width: this.getScreenSize().height/3*2*0.457+40, height: this.getScreenSize().height/3*2+40, marginTop:0, }}
               resizeMode="contain" source={require("./images/iphone.png")} />

</View>

*/