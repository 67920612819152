import './App.css';
import  { createSwitchNavigator, createAppContainer  }  from 'react-navigation';

import Start from './Start';


const AppNavigator = createSwitchNavigator({

Start: { screen: Start, navigationOptions: { title: 'Start', header: null  }, path: 'Start' },

})

export default createAppContainer(AppNavigator);