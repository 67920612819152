/*



*/

import {
    Dimensions,

} from 'react-native';


export const isActiveApp = {'ug':true,'pa':true,'bg':false,'kg':false};
export const startbild = require("./images/startbild20.png");
export const postcard = require("./images/Postkarte-Italien.png");
export const markenImpressum = 'Urlaubsgruss.com und PostkartenApp.de sind eingetragene Marken der Streuwerk GmbH.';

export const appData = {
'ug':{
'android':'market://details?id=de.Postkarte',
'ios':'itms-apps://itunes.apple.com/us/app/apple-store/id533470794?mt=8',
'androidweb':'https://play.google.com/store/apps/details?id=de.Postkarte',
'iosweb':'https://itunes.apple.com/de/app/id533470794',
'www':'https://www.urlaubsgruss.com'
},
'pa':{
'android':'market://details?id=de.Postkarte',
'ios':'itms-apps://itunes.apple.com/us/app/apple-store/id533470794?mt=8',
'androidweb':'https://play.google.com/store/apps/details?id=de.Postkarte',
'iosweb':'https://itunes.apple.com/de/app/id533470794',
'www':'https://www.postkartenapp.de'
},
'bg':{
'android':'market://details?id=de.Postkarte',
'ios':'itms-apps://itunes.apple.com/us/app/apple-store/id533470794?mt=8',
'androidweb':'https://play.google.com/store/apps/details?id=de.Postkarte',
'iosweb':'https://itunes.apple.com/de/app/id533470794',
'www':'https://www.urlaubsgruss.com'
},
'kg':{
'android':'market://details?id=de.Postkarte',
'ios':'itms-apps://itunes.apple.com/us/app/apple-store/id533470794?mt=8',
'androidweb':'https://play.google.com/store/apps/details?id=de.Postkarte',
'iosweb':'https://itunes.apple.com/de/app/id533470794',
'www':'https://www.urlaubsgruss.com'
}
}


let date = new Date();
export const infos = {
'email':'kontakt@streuwerk.com',
'support':'kundenservice@postkartenapp.de',
'betreff':'Anfrage',
'name':'Streuwerk GmbH',
'strasse':'Bauhofstraße 20',
'stadt':'68623 Lampertheim',
'telefon':'+49 (0) 6206-/ 95 117 08',
'supporttelefon':'+49 (0) 6206-/ 95 117 08',
'ustid':'DE276744370',
'hrb':'HRB 105104',
'amt':'Amtsgericht Darmstadt',
'vertretung':'Christian Reichenberger und Hendrik Mittas',
'von':'2011',
'bis':date.getFullYear(),
}

export const businessAnfrage = {
'mail':'marketing@urlaubsgruss.com',
'betreff':'Anfrage für Postkarten - Marketing',
}

export const menuVerlauf = {'stop1':'#191654','stop2':'#43C6AC','opacity':0.5,'hatPfad':false,'bg':'#bd0926', 'showFooter': false};

export function mLocale () {
        var loc = 'de';
        var locale = 'en';
        if ( loc.startsWith("de") ){
            locale = 'de';
        }
        if ( loc.startsWith("fr") ){
            locale = 'fr';
        }
    return locale;
};


//export const regularFont = 'Fredoka One';
//export const regularFont = 'Segoe Print';
//export const regularFont = 'Quicksand';
export const regularFont = 'Jost-Regular';
export const boldFont = 'Jost-Medium';
//export const bolditalicFont = 'Montserrat-BoldItalic';
export const blackFont = 'Jost-Bold';

let isTablet = Dimensions.get('window').height > Dimensions.get('window').width?false:true;

export const mFontSize = {
'head':isTablet?32:30,
'bighead':isTablet?40:34,
'subhead':isTablet?28:26,
'points':isTablet?24:22,
'text':isTablet?24:22,
'smalltext':isTablet?18:18,
'button':isTablet?20:18,
'buttonmenu':isTablet?10:10,
'menu':isTablet?18:18,
'icon':isTablet?32:32,
'headermenu':isTablet?16:16,
'footeradresse':isTablet?20:18,
'footerlink':isTablet?20:18,
'swiper':isTablet?24:22
}




let prim = '#29b6f6';

//let sig = '#bd0926';
let sig = '#ff4181';

export const MainColor = {
             'primary':prim,
             'signal':sig,
             'graydark':'#4b4b49',
             'darkgray':'#4b4b49',
             'graylight':'#777775',
             'grayultralight':'#dad8d8',
             'grayultraultralight':'#fafafa',
             'grayultraultralight2':'#eeeeee',
             'plholder':'#9e9e9e',
             'card':'#fafafa',
             'switchtint':'#ffffff',
             'background':'#FFFFFF',
             'green':'#8bc34a',
             'gelb':'#ffeb3b',
             'postgelb':'#fdc90c',
             'welcome':prim+'75',
             'iconprimary':'#ffffff',
             'btnprimary':prim,
             'dark':'#444444',
             'primarylight':prim+'33',
             'signallight':sig+'33',
             'goldlight':'#cbb74d33',
             'greenlight':'#8bc34a33',
             'shadow':'#aeaeae99',
             'schatten':'#444444',
             'gold':'#cbb74d',
             'blaugrau':'#7e8c99',
             'blaugraulight':'#b4bcc3',
             'blaugraulightmitalpha':'#b4bcc399',
             'dunkelblaugrau':'#525c60',
             'weiss':'#FFFFFF',
             'schwarz':'#000000',
             'menuBtnColor':'#ffffff',
             'menuBtnTextColor':'#535353',
             'menuIconColor':'#535353',
             'menuTextColor':'#535353',
             'menuHeadBack':prim,
             'menuHeadlineColor1':'#4b4b49',
             'menuHeadlineColor2':'#ffffff',
             'editfieldbgcolor':'#ecf6fb',
             'streuwerkgrau':'#535353',
             'streuwerkrot':'#c53e3e',
             'test':'#ff00ff',
             'test2':'#00ff00',
             'blau':'#0099ff',
             'verlaufamber50':'#b4daef',
             'verlaufamber100':'#b4daef',
             'verlaufamber200':'#b4daef',
             'verlaufpahell':'#fce28b',
             'verlaufpadunkel':'#f48381',
             'rosa':'#ffd2d9',
             'hellblau':'#b4daef',
             'hellblau50':'#d9ecf7',
             'hellblau25':'#ecf6fb',
             'hellgrun':'#c5e1a5',
             'hellgelb':'#fed899',
             'hellgelb50':'#feebcd',
             'transparent':'transparent',
             };


