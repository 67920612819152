import React, { Component } from 'react';



import {
  Dimensions,
  View,
  Text,
  Image,

} from 'react-native';

import * as Animatable from 'react-native-animatable';

import {appstrings} from './translate/Strings';

import {MainColor, regularFont, blackFont, mFontSize } from './Controller';

import { Icon } from 'react-native-elements';

import Svg, {

  LinearGradient,
  Rect,
  Stop,

} from 'react-native-svg';

export default class Qualitaet extends Component {

constructor(props) {
    super(props);

    this.state = {

    };
  }


componentDidMount(){

}

componentWillUnmount() {

}



goBack(){
    this.refs.myImageCropper.bounceOutRight();
    setTimeout(() => {  this.props.close(); }, 1000);

}



getScreenSize(){
    let w = Dimensions.get('window').width;
    let h = Dimensions.get('window').height;
    let isTablet = true;
    if ( h > w ){
        isTablet = false
    }

    return { width: w, height: h, isTablet:isTablet };
}

istEsEineGeradeZahl(value) {
 if (value%2 === 0)
  return true;
   else
  return false;
}

render() {



return (

<View ref={'myImageCropper'} onLayout={(event) => {this.props.onLayout(event)}} style={{ paddingTop:0, paddingBottom:0, width: '100%',justifyContent:'center', alignItems:'center', zIndex:9999}}>

<View ref={'qualiundnach_titel'} style={{ marginBottom: 75, width:320, height:120, zIndex:1, transform:[{rotate:'-10deg'}], justifyContent:'center', alignItems:'center'  }}>

     <Image style={{ zIndex: 1, position:'absolute', width:'100%', height:'100%', margin: 0, tintColor: MainColor.green}} resizeMode="stretch" source={require("./images/webseite/preisfeld.png")} />
     <Text style={{ zIndex: 1, fontFamily: blackFont, fontSize:mFontSize.head, textAlign:'center', color:'#ffffff' }} >
           {appstrings().strings.qualiundnach}</Text>

</View>

          <Svg height={'100%'} width={'100%'} style={{ position:'absolute'}}>

                <LinearGradient id="gradient34c" x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
                                  <Stop offset="0" stopColor={MainColor.hellblau} stopOpacity="1" />
                                  <Stop offset="1" stopColor={MainColor.rosa} stopOpacity="1" />
                </LinearGradient>

              <Rect height={'100%'} width={'100%'} fill="url(#gradient34c)" />
          </Svg>

{
this.props.isTablet ?
    <View style={{width:'80%', flexDirection:'row', alignItems:'flex-start', paddingBottom:50, justifyContent:'space-between',}}>


                <View style={{flexDirection:'column', width: '22%', alignItems:'center', justifyContent:'center', backgroundColor:'#ffffff99', padding:10,  paddingTop:20, borderRadius:25, shadowColor: MainColor.schwarz, shadowOffset: { width: 0, height: 3 }, shadowRadius: 5, shadowOpacity: 0.1, }}>
                                        <Icon size={60} name='sprout' type='material-community' color={MainColor.green}/>
                                        <Text style={{ marginTop:30, width:'90%', fontFamily: regularFont, textAlign:'center', color: MainColor.streuwerkgrau, fontSize: mFontSize.smalltext,  }} >{appstrings().strings.papierfsc}</Text>
               </View>


                <View style={{flexDirection:'column', width: '22%', height:'100%', alignItems:'center', backgroundColor:'#ffffff99', padding:10, paddingTop:20, borderRadius:25, shadowColor: MainColor.schwarz, shadowOffset: { width: 0, height: 3 }, shadowRadius: 5, shadowOpacity: 0.1, }}>
                                        <Icon size={60} name='power-plug' type='material-community' color={MainColor.green}/>
                                        <Text style={{ marginTop:30, width:'90%', fontFamily: regularFont, textAlign:'center', color: MainColor.streuwerkgrau, fontSize: mFontSize.smalltext,  }} >{appstrings().strings.serverstrom}</Text>
               </View>

                <View style={{flexDirection:'column', width: '22%', height:'100%', alignItems:'center', backgroundColor:'#ffffff99', padding:10, paddingTop:20, borderRadius:25, shadowColor: MainColor.schwarz, shadowOffset: { width: 0, height: 3 }, shadowRadius: 5, shadowOpacity: 0.1, }}>
                                        <Icon size={60} name='invert-colors' type='material-community' color={MainColor.green}/>
                                        <Text style={{ marginTop:30, width:'90%', fontFamily: regularFont, textAlign:'center', color: MainColor.streuwerkgrau, fontSize: mFontSize.smalltext,  }} >{appstrings().strings.druckfarbe}</Text>
               </View>

                <View style={{flexDirection:'column', width: '22%', alignItems:'center', justifyContent:'center', backgroundColor:'#ffffff99', padding:10, paddingTop:20,  borderRadius:25, shadowColor: MainColor.schwarz, shadowOffset: { width: 0, height: 3 }, shadowRadius: 5, shadowOpacity: 0.1, }}>
                                        <Animatable.View animation={"rubberBand"} easing="ease-out" iterationCount="infinite" >
                                        <Icon size={60} name='heart' type='material-community' color={MainColor.rosa}/>
                                        </Animatable.View>
                                        <Text style={{ marginTop:30, width:'90%', fontFamily: regularFont, textAlign:'center', color: MainColor.streuwerkgrau, fontSize: mFontSize.smalltext,  }} >{appstrings().strings.qualitaet}</Text>
               </View>



    </View>:
    <View style={{width:'80%', paddingBottom:50, backgroundColor:'#ffffff99', flexDirection:'column', borderRadius:25, shadowColor: MainColor.schwarz, shadowOffset: { width: 0, height: 3 }, shadowRadius: 5, shadowOpacity: 0.1,  }}>

        <View style={{padding:20, }}>
                <View style={{flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                                        <Icon containerStyle={{width:'20%'}} size={60} name='sprout' type='material-community' color={MainColor.green}/>
                                        <Text style={{ width:'80%', fontFamily: regularFont, paddingLeft:20, color: MainColor.streuwerkgrau, fontSize: mFontSize.smalltext,   }} >{appstrings().strings.papierfsc}</Text>
               </View>
       </View>
       <View style={{padding:20, }}>
                <View style={{flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                                        <Icon containerStyle={{width:'20%'}} size={60} name='power-plug' type='material-community' color={MainColor.green}/>
                                        <Text style={{ width:'80%', fontFamily: regularFont, paddingLeft:20, color: MainColor.streuwerkgrau, fontSize: mFontSize.smalltext,  }} >{appstrings().strings.serverstrom}</Text>
               </View>
       </View>
       <View style={{padding:20, }}>
                <View style={{flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                                        <Icon containerStyle={{width:'20%'}} size={60} name='invert-colors' type='material-community' color={MainColor.green}/>
                                        <Text style={{ width:'80%', fontFamily: regularFont, paddingLeft:20, color: MainColor.streuwerkgrau, fontSize: mFontSize.smalltext,  }} >{appstrings().strings.druckfarbe}</Text>
               </View>
       </View>
       <View style={{padding:20, }}>
                <View style={{flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                                        <Animatable.View style={{ width:'20%' }} animation={"rubberBand"} easing="ease-out" iterationCount="infinite" >
                                        <Icon containerStyle={{width:'100%'}} size={60} name='heart' type='material-community' color={MainColor.rosa}/>
                                        </Animatable.View>
                                        <Text style={{ width:'80%', fontFamily: regularFont, paddingLeft:20, color: MainColor.streuwerkgrau, fontSize: mFontSize.smalltext,  }} >{appstrings().strings.qualitaet}</Text>
               </View>
       </View>


    </View>
}



</View>
);

  }
}

