import React, { Component } from 'react';

import {
    View,
    Dimensions,
    TouchableOpacity,
    Text,

} from 'react-native';

import Svg, {

  Path,

} from 'react-native-svg';

import { mFontSize, boldFont, MainColor, infos } from './Controller';
import {appstrings} from './translate/Strings';

import { Icon } from 'react-native-elements';

const pfadOnlyCurve = 'M1079.59,70.7C1030.21,77.6,987.9,81.88,955,84.64c-88,7.38-131,5.5-160.07,3.53-9.23-.62-20.15-1.75-26.27-2.41C743.3,83,692.76,78,628.58,67c0,0-152.61-25.07-268-40.92-5-.69-32.28-4.4-65.2-7-12.88-1-23.62-1.63-33.63-2-2.31-.09-18.17-.72-33.62-.68-12.88,0-31.49.55-68.14,4.07-38.63,3.71-61,5.86-91.82,11.65C49.84,35.52,26.63,40.6,0,48.27V0H1080Q1079.79,35.36,1079.59,70.7Z';


export default class FooterMini extends Component {



constructor() {
        super();


        this.state = {

            showPraesentation: false,

        }

    }





componentDidMount() {

}





getScreenSize(){
    let w = Dimensions.get('window').width;
    let h = Dimensions.get('window').height;
    let isTablet = true;
    if ( h > w ){
        isTablet = false
    }

    return { width: w, height: h, isTablet:isTablet };
}

render() {



    return (

<>
  <style type="text/css">{`
    @font-face {
      font-family: 'FontAwesome';
      src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'MaterialCommunityIcons';
      src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
    }
  `}</style>


<View style={{ width:'100%', paddingBottom:0, justifyContent:'center', alignItems:'center' }} onLayout={(event) => {this.props.onLayout(event) }}>

                  <Svg height={this.getScreenSize().isTablet?40:20} width={'100%'} style={{ marginBottom:-1, transform:[{rotate:'180deg'}], opacity:1 }} viewBox="0 0 1080 90" preserveAspectRatio="none">

                      <Path id="path" fillRule="evenodd" fill={MainColor.darkgray} fillOpacity="1" d={pfadOnlyCurve} />
                  </Svg>



<View style={{ width: this.getScreenSize().width, backgroundColor:MainColor.graydark,
        marginTop:0, flexDirection:'row', justifyContent:'center'}}>
{ !this.getScreenSize().isTablet ?
    <TouchableOpacity style={{ justifyContent:'center'}} onPress={()=>this.props.makeAction('Start') }>
     <Text style={{ fontFamily:boldFont, fontSize:mFontSize.footerlink, textAlign:'center', color:'#ffffff', margin:20, textDecorationLine: 'underline' }} >
           {appstrings().strings.start}</Text>
    </TouchableOpacity>:null
}

    <TouchableOpacity style={{ justifyContent:'center'}} onPress={()=>this.props.makeAction('Impressum') }>
     <Text style={{ fontFamily:boldFont, fontSize:mFontSize.footerlink, textAlign:'center', color:'#ffffff', margin:20, textDecorationLine: 'underline' }} >
           {appstrings().strings.impressum}</Text>
    </TouchableOpacity>

    <TouchableOpacity style={{ justifyContent:'center'}} onPress={()=>this.props.makeAction('Datenschutz') }>
     <Text style={{ fontFamily:boldFont, fontSize:mFontSize.footerlink, textAlign:'center', color:'#ffffff', margin:20, textDecorationLine: 'underline' }} >
           {appstrings().strings.datenschutz}</Text>
    </TouchableOpacity>

    <TouchableOpacity style={{ justifyContent:'center'}} onPress={()=>this.props.makeAction('agb') }>
     <Text style={{ fontFamily:boldFont, fontSize:mFontSize.footerlink, textAlign:'center', color:'#ffffff', margin:20, textDecorationLine: 'underline' }} >
           {appstrings().strings.agb}</Text>
    </TouchableOpacity>
</View>


     <View style={{ width: this.getScreenSize().width, flexDirection:'row', justifyContent:'center', alignItems:'center', paddingBottom:30, paddingTop:20, backgroundColor:MainColor.darkgray,  }}>
          <Icon size={14} name='copyright' type='font-awesome' color={MainColor.weiss}/>
          <Text style={{fontFamily:boldFont, color:MainColor.weiss, marginLeft:10, fontSize:16, }}>{infos.von+' - '+infos.bis}</Text>
     </View>


</View>

</>
    );
  }
}

/*

    <Text style={{ position:'absolute', top:40, fontFamily:regularFont, color: MainColor.streuwerkgrau, fontSize: this.props.isTablet?32:24, fontWeight:'bold', textAlign:'center' }} >
    {appstrings().strings.unseremarken+':'}</Text>

*/