import stringsEN from './en.js';
import stringsDE from './de.js';
import stringsFR from './fr.js';

//import DeviceInfo from 'react-native-device-info';



export function appstrings(){

    var str = stringsEN;
    var locale = "de";//DeviceInfo.getDeviceLocale();

    if ( locale.startsWith("de") ){
        str = stringsDE;
    }

    if ( locale.startsWith("fr") ){
        str = stringsFR;
    }

    return str;
}