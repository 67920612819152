import React, { Component } from 'react';

import {
    View,
    Dimensions,
    Image,
    Text,
    ScrollView,
    Animated,


} from 'react-native';



import { mFontSize, regularFont, boldFont, blackFont, MainColor, infos, isActiveApp, markenImpressum } from './Controller';
import {appstrings} from './translate/Strings';


import Footer from './Footer';

import { Icon } from 'react-native-elements';



export default class Impressum extends Component {



constructor() {
        super();


        this.state = {

            scrollY: new Animated.Value(0),
            posButton: 0,
        }

    }




close(){
    //this.refs.KoopView.bounceOutRight(750);
    //setTimeout(() => {
    this.props.onClose();
     //}, 750);
}
componentDidMount() {

}




getScreenSize(){
    let w = Dimensions.get('window').width;
    let h = Dimensions.get('window').height;
    let isTablet = true;
    if ( h > w ){
        isTablet = false
    }

    return { width: w, height: h, isTablet:isTablet };
}

getContainerWidth(){

    let gwidth = this.getScreenSize().isTablet?this.getScreenSize().width*0.15:this.getScreenSize().width*0.2;

    let width = gwidth;

    if ( isActiveApp.ug ){
        width = gwidth;
    }
    if ( isActiveApp.pa ){
        width = width+(gwidth);
    }
    if ( isActiveApp.bg ){
        width = width+(gwidth);
    }
    if ( isActiveApp.kg ){
        width = width+(gwidth);
    }

return width;
}

getThumbWidth(){
return this.getScreenSize().isTablet?this.getScreenSize().width*0.1:this.getScreenSize().width*0.15;
}

render() {


    return (

<>
  <style type="text/css">{`
    @font-face {
      font-family: 'FontAwesome';
      src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
    }
    @font-face {
      font-family: 'MaterialCommunityIcons';
      src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
    }
  `}</style>


<View style={this.props.style}>

<ScrollView ref={'ScrollMainKoop'} scrollEventThrottle={16} onScroll={Animated.event([{nativeEvent: {contentOffset: {y: this.state.scrollY}}}])} style={{ width:'100%', backgroundColor:'#ffffff' }} contentContainerStyle={{ alignItems:'center'}}>




<View style={{ width:this.getScreenSize().isTablet?'60%':'80%', marginTop:this.getScreenSize().isTablet?0:50, marginBottom:100,  }}>


         <Text style={{ marginTop:this.getScreenSize().isTablet?50:30, fontFamily:blackFont, fontSize:mFontSize.bighead, textAlign:'center', color:MainColor.graydark }} >
               {appstrings().strings.imp_titel}</Text>




         <Text style={{ marginTop:50, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.imp_angaben}</Text>

         <Text style={{ marginTop:this.getScreenSize().isTablet?50:30, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.sitz}</Text>

         <Text style={{ marginTop:10, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {infos.name+'\n'+infos.strasse+'\n'+infos.stadt}</Text>

<View style={{ flexDirection:this.getScreenSize().isTablet?'row':'column', marginTop:50,  }}>
         <Text style={{ fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.imp_hrb}</Text>

         <Text style={{ marginLeft:this.getScreenSize().isTablet?10:0, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {infos.hrb}</Text>
</View>

<View style={{ flexDirection:this.getScreenSize().isTablet?'row':'column', marginTop:10,  }}>
         <Text style={{ fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.imp_gericht}</Text>

         <Text style={{ marginLeft: this.getScreenSize().isTablet?10:0, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {infos.amt}</Text>
</View>




         <Text style={{ marginTop:50, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.imp_verteten}</Text>

         <Text style={{ marginTop:10, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {infos.vertretung}</Text>



         <Text style={{ marginTop:50, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.imp_ust}</Text>

         <Text style={{ marginTop:10, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {infos.ustid}</Text>


          <Text style={{ marginTop:50, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.kontakt}</Text>


<View style={{ flexDirection:this.getScreenSize().isTablet?'row':'column', marginTop:10,  }}>
         <Text style={{ fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.telefon}</Text>

         <Text style={{ marginLeft: this.getScreenSize().isTablet?10:0, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {infos.telefon}</Text>
</View>


<View style={{ flexDirection:this.getScreenSize().isTablet?'row':'column', marginTop:10,  }}>
         <Text style={{ fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.email}</Text>

     <View style={{ flexDirection:'row', alignItems:'center', marginLeft: this.getScreenSize().isTablet?10:0,  }}>
          <Text style={{fontFamily:blackFont, color:MainColor.graydark, marginTop:2, marginRight:1, fontSize:mFontSize.text, }}>{infos.email.split('@')[0]}</Text>
          <Icon size={mFontSize.text} name='at' type='material-community' color={MainColor.graydark}/>
          <Text style={{fontFamily:blackFont, color:MainColor.graydark, marginLeft:1, fontSize:mFontSize.text, }}>{infos.email.split('@')[1]}</Text>
     </View>

</View>

<View style={{ flexDirection: 'row', marginTop:100, width:this.getContainerWidth(), marginBottom:this.getScreenSize().isTablet?50:20, justifyContent:'space-between' }}>

        <Image style={{ width: this.getThumbWidth(), height: this.getThumbWidth(), marginTop:0, }}
               resizeMode="contain" source={require("./images/urlaubsgruss_quad.png")} />

        <Image style={{ width: this.getThumbWidth(), height: this.getThumbWidth(), marginTop:0, }}
               resizeMode="contain" source={require("./images/postkartenapp_quad.png")} />
{ isActiveApp.bg ?
        <Image style={{ width: this.getThumbWidth(), height: this.getThumbWidth(), marginTop:0, }}
               resizeMode="contain" source={require("./images/bilderguru_quad.png")} />:null
}
{ isActiveApp.kg ?
        <Image style={{ width: this.getThumbWidth(), height: this.getThumbWidth(), marginTop:0, }}
               resizeMode="contain" source={require("./images/kartenguru_quad.png")} />:null
}


</View>

         <Text style={{ marginTop:0, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {markenImpressum}</Text>



         <Text style={{ marginTop:50, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.hinweis}</Text>

         <Text style={{ marginTop:10, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.hinweis1}
            <Text style={{ fontFamily:boldFont, textDecorationLine: 'underline', }} onPress={()=>{ window.open(appstrings().strings.hinweis2, "_blank"); }}>{appstrings().strings.hinweis2}</Text>
            <Text style={{  }} >{appstrings().strings.hinweis3}</Text>
         </Text>

         <Text style={{ marginTop:50, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.hinweis4}</Text>



         <Text style={{ marginTop:50, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.haftung}</Text>

         <Text style={{ marginTop:10, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.haftung1}</Text>

         <Text style={{ marginTop:50, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.haftung2}</Text>



         <Text style={{ marginTop:50, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.haftunglinks}</Text>

         <Text style={{ marginTop:10, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.haftunglinks1}</Text>

         <Text style={{ marginTop:50, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.haftunglinks2}</Text>




         <Text style={{ marginTop:50, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.urheber}</Text>

         <Text style={{ marginTop:10, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.urheber1}</Text>

         <Text style={{ marginTop:50, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.urheber2}</Text>



         <Text style={{ marginTop:50, fontFamily:blackFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.bildlizenz}</Text>


         <Text style={{ marginTop:50, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.bilder}</Text>

         <Text style={{ marginTop:20, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.bilder2}</Text>

         <Text style={{ marginTop:20, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.illustrationen}</Text>

         <Text style={{ marginTop:20, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.fonts}</Text>

         <Text style={{ marginTop:20, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.jost}</Text>

         <Text style={{ marginTop:20, fontFamily:regularFont, fontSize:mFontSize.text, textAlign:'left', color:MainColor.graydark }} >
               {appstrings().strings.parisienne}</Text>


</View>









<Footer
makeAction={(action)=>{ this.props.makeAction(action) }}
/>

</ScrollView>


</View>
</>
    );
  }
}

/*

    <Text style={{ position:'absolute', top:40, fontFamily:regularFont, color: MainColor.streuwerkgrau, fontSize: this.props.isTablet?32:24, fontWeight:'bold', textAlign:'center' }} >
    {appstrings().strings.unseremarken+':'}</Text>

*/