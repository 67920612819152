//"marken": "Urlaubsgruss.com, PostkartenApp.de, BilderGuru.de und KartenGuru.de sind eingetragene Marken der Streuwerk GmbH.",

const strings = {
  "titel": "PostkartenApp.de",

  "menu": "Menü",
  "close": "Schließen",
  "back": "Zurück",
  "fragenundhilfe": "Fragen und Hilfe zur App",

  "nachoben": "Nach Oben",
  "start": "Start",
  "startseite": "Startseite",
  "uberuns": "Die App",
  "philosopie": "Nachhaltigkeit",
  "sicherheit": "Sicherheit",
  "impressum": "Impressum",
  "agb": "AGBs",
  "datenschutz": "Datenschutz",
  "kooperationen": "Kooperationen",
  "business": "Geschäftskunden",
  "presse": "Presse",
  "cookies": "Diese Webseite nutzt weder Cookies noch Tracking-Tools!\nWir speichern keine Daten von Ihnen!",

  "sofunzt": "So einfach geht´s:",
  "firma": "Eine App der:",
  "firma2": "Streuwerk GmbH",

  "start_slogan": "Einfach\nPersönlich!",
  "start_titel": "Eigene Bilder als ECHTE\nPostkarte verschicken.\nWeltweit!",
  "start_text": "Gestalten Sie im handumdrehen wunderschöne Postkarten mit Ihren eigenen Bildern.\n\nMit unserem Direktversand können Sie Ihre Postkarten ganz einfach weltweit verschicken.",

  "schritt1": "Wählen Sie einen Collage-Style für Ihre Bilder aus.",
  "schritt2": "Schreiben Sie ein paar liebe Worte und wählen Sie den Empfänger aus.",
  "schritt3": "Wir produzieren Ihre Postkarten und verschicken diese mit der Post.",



  "qualiundnach": "Qualität und\nNachhaltigkeit",
  "papierfsc": "Für unsere Produkte nutzen wir ausschließlich Papier, welches aus FSC®-zertifizierten Wäldern und kontrollierten Quellen stammt.",
  "serverstrom": "Unsere Server, unser Büro und unsere Produktion laufen zu 100% mit Ökostrom.",
  "druckfarbe": "Unsere verwendete Druckfarbe ist zu 100% unbedenklich und darf sogar mit Lebensmitteln in Kontakt kommen.",
  "qualitaet": "Alle Produkte werden von uns selbst mit viel Liebe zum Detail hergestellt und sorgfältig geprüft, bis wir diese an Sie verschicken!",

  "hundertproz": "100%",
  "deutsche": "Deutsche",
  "server": "Server",
  "sec_titel": "Echte Sicherheit und\n100% DSGVO-Konform",
  "sec_text": "Alle unsere Apps und Dienste laufen über TÜV-zertifizierte Rechenzentren.\nDie Standorte befinden sich ausschließlich in Deutschland um ein höchstmaß an Sicherheit zu garantieren.\n\nAuch als Geschäftskunde sind Sie mit unserer Auftragsdaten-Verarbeitungs-Vereinbarung (AVV) in jedem Fall bei uns auf der sicheren Seite.",
  "busikd": "Geschäftskunde?",
  "weitereinfos": "Dann bitte hier klicken!",

//Impressum

  "imp_titel": "Impressum",
  "sitz": "Sitz der Gesellschaft:",
  "imp_angaben": "Angaben gemäß § 5 TMG:",
  "imp_hrb": "Handelsregister:",
  "imp_gericht": "Registergericht:",
  "imp_verteten": "Vertreten durch:",
  "imp_ust": "Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:",
  "hinweis": "EU-Streitschlichtung:",
  "hinweis1": "Die EU-Kommission bietet die Möglichkeit zur Online-Streitbeilegung auf einer von ihr betriebenen Online-Plattform. Diese Plattform ist über den externen Link ",
  "hinweis2": "http://ec.europa.eu/consumers/odr/",
  "hinweis3": " zu erreichen.",
  "hinweis4": "Wir sind nicht verpflichtet an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen und bieten ein solches Verfahren nicht an.",
  "haftung": "Haftung für Inhalte",
  "haftung1": "Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.",
  "haftung2": "Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.",
  "haftunglinks": "Haftung für Links",
  "haftunglinks1": "Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.",
  "haftunglinks2": "Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.",
  "urheber": "Urheberrecht",
  "urheber1": "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.",
  "urheber2": "Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.",

  "bildlizenz": "Bildnachweise und Lizenzhinweise:",
  "bilder": "Startseite: Stock Bild - Frau mit Botschaft von © Colourbox.de",
  "bilder2": "Urlaubsbilder auf Postkarten sind von © Freepik.com",
  "illustrationen": "Alle Illustrationen stammen von storyset.com - © Illustration by Freepik Storyset",
  "fonts": "Die eingesetzen Schriften (Fonts) unterliegen der SIL OPEN FONT LICENSE Version 1.1 (http://scripts.sil.org/OFL)",
  "jost": "Font: Jost - Copyright 2020 The Jost Project Authors (https://github.com/indestructible-type/Jost)",
  "parisienne": "Font: Parisienne - Astigmatic -  Principal design",


}



export default {
  strings
};

