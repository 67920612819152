

import React, { Component } from 'react';



import {
  View,
  ActivityIndicator,
  Dimensions,

} from 'react-native';

//import AsyncStorage from '@react-native-community/async-storage';


import * as Animatable from 'react-native-animatable';

import { WebView } from "react-native-webview";

import {MainColor} from './Controller';


export default class MyWebView extends Component {
constructor(props) {
    super(props);

    this.state = {

        close: 'https://www.urlaubsgruss.com/mobileapps2/closewebview.php',
        testtext: '',
        isTablet: false,

    };
}

componentWillMount() {

}

componentWillUnmount() {

}



_onNavigationStateChange(webViewState){
  if ( webViewState.url === this.state.close ){
        this.goBack();
  }
  this.setState({ testtext: webViewState.url });
}

ActivityIndicatorLoadingView() {

    return (

      <ActivityIndicator
        color={MainColor.primary}
        size='large'
        style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center'}}
      />
    );
  }









close(){
if ( this.refs.MyWebView !== undefined ){
    this.refs.MyWebView.bounceOutRight();
}
setTimeout(() => {  this.props.onClose() }, 750);
}


getScreenSize(){
    let w = Dimensions.get('window').width;
    let h = Dimensions.get('window').height;
    let isTablet = true;
    if ( h > w ){
        isTablet = false
    }

    return { width: w, height: h, isTablet:isTablet };
}



onMessage(data){

    if ( data === 'close' ){

        this.goBack();
    }

}

  render() {



    return (

    <>
      <style type="text/css">{`
        @font-face {
          font-family: 'FontAwesome';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
        }
      `}</style>

<Animatable.View ref={'MyWebView'} animation="bounceInLeft" style={this.props.style}>

<View style={{ position:'absolute', left: this.getScreenSize().isTablet?'25%':0, top:0, height:this.getScreenSize().height-this.props.miniFooterHeight-50, width:this.getScreenSize().isTablet?'50%':'100%', overflow: 'hidden'}}>
        <WebView
                                     ref="webview"
                                     source={{uri: this.props.url}}
                                     style={{marginTop: 0, width: '100%', height:'100%'}}
                                     onNavigationStateChange={this._onNavigationStateChange.bind(this)}
                                     onMessage={(event) => { this.onMessage(event.nativeEvent.data); }}
                                     javaScriptEnabled = {true}
                                     domStorageEnabled = {true}
                                     startInLoadingState={true}
                                     renderLoading={this.ActivityIndicatorLoadingView}
                                     useWebKit={true}
                                     allowFileAccess={true}
                                     allowUniversalAccessFromFileURLs={true}
                                     originWhitelist={['*']}
                                   />
</View>




</Animatable.View>

</>
    );
  }
}

