

import React, { Component } from 'react';



import {
  View,
  ActivityIndicator,

} from 'react-native';

//import AsyncStorage from '@react-native-community/async-storage';



import { WebView } from "react-native-webview";

import {MainColor} from './Controller';


export default class Video extends Component {
constructor(props) {
    super(props);

    this.state = {

        close: 'https://www.urlaubsgruss.com/mobileapps2/closewebview.php',
        testtext: '',
        isTablet: false,

    };
}

componentWillMount() {

}

componentWillUnmount() {

}



_onNavigationStateChange(webViewState){
  if ( webViewState.url === this.state.close ){
        this.goBack();
  }
  this.setState({ testtext: webViewState.url });
}

ActivityIndicatorLoadingView() {

    return (

      <ActivityIndicator
        color={MainColor.primary}
        size='large'
        style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center'}}
      />
    );
  }











  render() {



    return (

    <>
      <style type="text/css">{`
        @font-face {
          font-family: 'FontAwesome';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
        }
      `}</style>

<View style={this.props.style} onLayout={(event) => {this.props.onLayout(event) }}>
        <WebView
                                     ref="webview"
                                     source={{uri: this.props.url}}
                                     style={{marginTop: 0, width: '100%', height:'100%'}}
                                     onNavigationStateChange={this._onNavigationStateChange.bind(this)}
                                     onMessage={(event) => { this.onMessage(event.nativeEvent.data); }}
                                     javaScriptEnabled = {true}
                                     domStorageEnabled = {true}
                                     startInLoadingState={true}
                                     renderLoading={this.ActivityIndicatorLoadingView}
                                     useWebKit={true}
                                     allowFileAccess={true}
                                     allowUniversalAccessFromFileURLs={true}
                                     originWhitelist={['*']}
                                   />
</View>

</>
    );
  }
}

